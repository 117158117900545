<template>
<div v-if="isApplicable">
    <v-text-field solo :label="label" prefix="+ " v-model="input" autocomplete
          :rules="textValidator" validate-on-blur :hint="hint"></v-text-field>
</div>
</template>

<script>
export default {
  name: 'PhoneField',
  props: ['label', 'value', 'isApplicable', 'hint'],
  data () {
    return {
      input: this.value,
      textValidator: [
        (input) => {
        // depends on the parent component to determine if this input is a applicable field for the user.
        // e.g. if the user is a giver, then this phone field is not used and should be hidden in the UI
        // and thus no validation rule is needed
          if (this.isApplicable) {
            return (input && /^[0-9 ]+$/.test(input) && input.length <= 12) ||
              'Must be in the International Phone format. E.g. +60 161234567 should become 60161234567'
          } else {
            return true
          }
        }
      ]
    }
  },
  watch: {
    input (newValue) {
      this.$emit('update:value', newValue)
    }
  }
}
</script>

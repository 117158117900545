<template>
    <v-text-field solo label= "Email" v-model="input" autocomplete
        :rules="emailValidator" @blur="checkEmail" ref='emailField' :error-messages="errormsg">
    </v-text-field>
</template>
<script>
import Vue from 'vue'
export default {
  name: 'EmailField',
  props: ['useremail', 'exclusions', 'isValidated'],
  data () {
    return {
      errormsg: '',
      input: this.useremail,
      emailValidator: [
        (input) => {
          /**
           * email regex reference: https://www.w3resource.com/javascript/form/email-validation.php
           * a@b.c, there must at least 5 characters
           */
          const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@([a-zA-Z0-9])+(?:\.[a-zA-Z0-9-]+)*$/
          if (input && input.length >= 5 && emailRegex.test(input)) {
            return true
          } else {
            return 'Email must be in valid email format. E.g. greatgiving@iocharity.com'
          }
        }
      ]
    }
  },
  methods: {
    /**
     * Since checking if an email has been registered will involve an async-API access to the account service,
     *  Vuetify validation always check immediately and thus can result in an inaccurate validation.
     *  Hence, an additional method checkEmail() will be called on blur event using checkEmail()
     *    to check if the email has been registered,
     *    if the component is set to be required  checking the uniqueness of the email
     */
    checkEmail () {
      // if email is not part of the exclusions, it is valid no matter if it is unique
      if (this.exclusions.includes(this.input)) {
        this.errormsg = ''
        this.$emit('update:isValidated', true)
        return
      }
      // if the input already has a validation error, no check is needed
      // console.log(this.$refs.emailField.errorBucket.length < 1)
      if (this.$refs.emailField.errorBucket.length < 1) {
        // console.log(this.$refs.emailField.errorCount)
        Vue.$publicApi.get('/account/searchexactemail',
          {
            params: { email: this.input }
          })
          .then(() => {
            console.log(this.input + 'is found')
            this.errormsg = `Email ${this.input} has been used. Please try another email.`
            this.$emit('update:isValidated', false)
          })
          .catch(() => {
            this.errormsg = ''
            this.$emit('update:isValidated', true)
            console.log('ok')
          })
      }
    }
  },
  watch: {
    input (newValue) {
      this.$emit('update:useremail', newValue)
    }
  }
}
</script>

<template>
<div v-if="isApplicable">
    <v-row justify="start">
      <v-col cols=8>
          <v-textarea solo label="Community/City" v-model="addressInput" autocomplete rows=2
          hint="The main geographical area that represents your charity (E.g. Ipoh, JB)"
            validate-on-blur>
            <template v-slot:append>
            <v-btn  x-small @click = "geocode" fab elevation="9" :loading="isGeoencodeLoading"
              class="mt-2">
              <v-icon  small color="primary">mdi-arrow-right-bold</v-icon>
            </v-btn>
          </template>
          </v-textarea>
      </v-col>
      <v-col  cols=3 class="mb-5" align-self="center">
        <v-btn  @click = "getGeolocation"  fab elevation="0"  :loading="isGeoLoading">
          <v-icon color="primary">mdi-crosshairs-gps</v-icon>
        </v-btn>
      </v-col>
    </v-row>
      <v-text-field  v-model="geoDisplay" readonly dense outlined :error-messages="errorMessage"
          :rules="geoValidator" class="mt-n7">
      </v-text-field>
</div>
</template>

<script>
import Vue from 'vue'
// import axios from 'axios'
export default {
  name: 'GeolocationField',
  props: ['geolocation', 'isApplicable'],
  data () {
    return {
      addressInput: '',
      errorMessage: '',
      isGeoLoading: false,
      isGeoencodeLoading: false,
      geo: {
        lat: this.geolocation.lat,
        lng: this.geolocation.lng
      },
      geoValidator: [
        (input) => {
        // depends on the parent component to determine if this input is a applicable field for the user.
        // e.g. if the user is a giver, then charity input field will be hidden and no validation is needed.
          if (this.isApplicable) {
            return (input && input !== 'Your Geolocation') ||
              'Please specify your location for your givers via Address bar or Your GPS above'
          } else {
            return true
          }
        }
      ]
    }
  },
  computed: {
    geoDisplay () {
      if (this.geo.lat === 0.0 && this.geo.lng === 0.0) {
        return 'Your Geolocation'
      } else {
        const roundedLat = Math.round(this.geo.lat * 10000) / 10000
        const roundedLng = Math.round(this.geo.lng * 10000) / 10000
        return `Lat:${roundedLat} Long:${roundedLng}`
      }
    }
  },
  methods: {
    getGeolocation () {
      this.isGeoLoading = true

      if ('geolocation' in navigator) {
        // console.log('geo')
        navigator.geolocation.getCurrentPosition(
          (coordination) => {
            this.errorMessage = ''
            this.geo = {}
            this.geo.lat = coordination.coords.latitude
            this.geo.lng = coordination.coords.longitude
            // console.log('geo')
          },
          (e) => {
            // console.log('failt to retrieve location')
            this.errorMessage = 'PERMISSION REQUIRED.Turn on through your Browser'
          }
        )
      } else {
        this.errorMessage = 'Opps! seems like your Web Browser (where you install your PWA) does not support this'
      }
      this.isGeoLoading = false
    },
    geocode () {
      // console.log(this.addressInput, 'geocode')
      if (this.addressInput) {
        // const encoded = encodeURI(this.addressInput)
        // console.log(`Address: ${encoded}`)
        /*
        const uri = 'https://maps.googleapis.com/maps/api/geocode/json?'
        axios.get(uri, {
          params: {
            address: encoded,
            key: 'AIzaSyDaaKSEWYgYN2ZmZJoJlKLlTkfvgraAXtU'
          }
        }).then((response) => {
          const data = response.data.results
          console.log(data)
          console.log(data[0])
          const geo = data[0].geometry.location
          console.log(geo)
          this.geolocation.lat = geo.lat
          console.log(geo.lat)
          this.geolocation.lng = geo.lng
          console.log(geo.lng)
        })
        */
        Vue.$geocoder.get('', {
          params: {
            address: this.addressInput
          }
        }).then((response) => {
          // console.log(response.data)
          this.errorMessage = ''
          const geo = response.data
          // console.log(geo)
          this.geo = {}
          this.geo.lat = geo.lat
          // console.log(geo.lat)
          this.geo.lng = geo.lng
          // console.log(geo.lng)
        }).catch(() => {
          this.errorMessage = 'GeoService fails to connect. Please try later (check connection, etc)'
        })
      }
    }
  },
  watch: {
    geo: {
      deep: true,
      handler (newValue) {
        console.log(newValue)
        this.$emit('update:geolocation', newValue)
      }

    }
  },
  created () {
    if (this.isApplicable) {
      this.geo = {
        lat: this.geolocation.lat,
        lng: this.geolocation.lng
      }
    }
  }
}
</script>
